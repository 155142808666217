<script>
import { formatPhoneNumber } from '../../utils'

export default {
  name: 'CustomerSearchItem',
  props: {
    customer: { type: Object, required: true },
    selected: { type: Boolean, default: false },
  },
  computed: {
    phoneNumber() {
      return this.formatPhoneNumber(this.customer.phone)
    },
    className() {
      const className = {}
      className['bg-turquoise-light text-white'] = this.selected
      className['hover:bg-turquoise-light hover:text-white'] = this.selected
      return className
    },
  },
  methods: {
    formatPhoneNumber,
  },
}
</script>

<template>
  <li class="p-2 cursor-pointer hover:bg-grey-lighter" :class="className">
    <p class="text-xs font-semibold">
      #{{ customer.id }} {{ customer.name }}
    </p>
    <p class="text-xs truncate">
      {{ phoneNumber }} <span v-if="customer.email">- {{ customer.email }}</span>
    </p>
  </li>
</template>

<style scoped></style>
