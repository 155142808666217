export const actions = {
  async getDemoEmail(_, email) {
    try {
      return this.$axios.post('/demo-email', { email }).then((res) => {
        return Promise.resolve(res)
      })
    }
    catch (error) {
      return {
        success: false,
        error,
      }
    }
  },

  async requestSubscription(_, form) {
    return await this.$axios.post('/leads', form).then(({ data }) => {
      return Promise.resolve(data)
    }).catch((error) => {
      const errors = error.response.data.errors

      return Promise.resolve({
        success: false,
        errors,
      })
    })
  },
}
