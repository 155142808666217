export function state() {
  return {
    list: [],
    setupIntent: '',
  }
}

export const mutations = {
  SET_ITEMS: (state, subscription) => (state.list = subscription),
  SET_SETUP_INTENT: (state, setupIntent) => (state.setupIntent = setupIntent),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, params) {
    dispatch('wait/start', 'subscription.index', { root: true })
    return this.$axios
      .$get(`/subscription`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data)
        dispatch('wait/end', 'subscription.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'subscription.index', { root: true })
      })
  },
  async confirmPayment({ state }) {
    try {
      if (this.$stripe) {
        await this.$stripe.confirmCardPayment(state.secret, {
          payment_method: {
            card: {
              token: state.cardToken,
            },
            billing_details: {
              name: state.customerName,
            },
          },
        })
      }
    }
    catch {
      console.log(404)
      // handle error
    }
  },
  async validateCard({ dispatch }, attr) {
    try {
      if (this.$stripe) {
        const response = await this.$stripe.confirmCardSetup(
          attr.clientSecret,
          {
            payment_method: {
              card: attr.card,
              billing_details: { name: attr.cardHolderName },
            },
          },
        )
        if (!response.error) {
          await dispatch('updateCard', response)
        }
        else {
          this.$notify({
            type: 'error',
            title: 'Card Error',
            text: response.error.message,
          })
        }
      }
    }
    catch {
      console.log(404)
    }
  },

  updateCard({ dispatch }, params) {
    dispatch('wait/start', 'subscription.index', { root: true })
    return this.$axios
      .$post(
        '/stripe/updatePaymentMethod',
        {
          params,
        },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Card Updated',
          text: data.success,
        })
        return Promise.resolve()
      })
      .catch(() => {
        dispatch('wait/end', 'subscription.index', { root: true })
      })
  },

  getSetupIntent({ commit, dispatch, state }, params) {
    dispatch('wait/start', 'subscription.index', { root: true })
    return this.$axios
      .$get('/stripe/setupIntent', {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_SETUP_INTENT', data.intent)
        dispatch('wait/end', 'subscription.index', { root: true })
        return Promise.resolve(state.setupIntent)
      })
      .catch(() => {
        dispatch('wait/end', 'subscription.index', { root: true })
      })
  },

  cancelSubscription({ dispatch }, params) {
    dispatch('wait/start', 'subscription.index', { root: true })
    return this.$axios
      .$post(
        '/stripe/cancelSubscription',
        {
          params,
        },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Subscription Status',
          text: data.message,
        })
        dispatch('fetchItems')
        return Promise.resolve()
      })
      .catch(() => {
        dispatch('wait/end', 'subscription.index', { root: true })
      })
  },
  openBillingPortal({ dispatch }) {
    dispatch('wait/start', 'subscription.index', { root: true })

    const current = window.location.pathname.includes('/reports') ? window.location.pathname : '/customers'

    return this.$axios
      .$get(`/stripe/billing-portal?redirect=${current}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        window.location.href = data.url
        return Promise.resolve()
      })
      .catch(() => {
        dispatch('wait/end', 'subscription.index', { root: true })
      })
  },
}
