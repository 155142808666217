export function state() {
  return {
    list: [],
    popularList: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, items) => (state.list = items),
  SET_POPULAR_ITEMS: (state, items) => (state.popularList = items),
}

export const actions = {
  recentItems({ commit, dispatch, state }, id) {
    dispatch('wait/start', 'customers.items.index', { root: true })

    return this.$axios
      .$get(`/customers/${id}/recent-items`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'customers.items.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'customers.items.index', { root: true })
      })
  },

  popularItems({ commit, dispatch, state }, id) {
    dispatch('wait/start', 'customers.items.index', { root: true })

    return this.$axios
      .$get(`/customers/${id}/popular-items`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_POPULAR_ITEMS', data.data)
        dispatch('wait/end', 'customers.items.index', { root: true })
        return Promise.resolve(state.popularList)
      })
      .catch(() => {
        dispatch('wait/end', 'customers.items.index', { root: true })
      })
  },
}
