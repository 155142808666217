import { isNullOrUndefined } from '../utils'

export function state() {
  return {
    list: [],
    selected: null,
    totalItems: '',
    isTerminalPage: false,
  }
}

export const mutations = {
  SET_ITEMS: (state, locations) => {
    state.list = locations
  },
  SET_SELECTED_LOCATION: (state, location) => {
    state.selected = location
  },
  TOTAL_ITEMS: (state, totalItems) => (state.totalItems = totalItems), // ??
  SET_TERMINAL_PAGE_STATUS: (state, event) => (state.isTerminalPage = event), // ??
}

export const actions = {
  fetchItems({ commit, dispatch, state }, params) {
    dispatch('wait/start', 'locations.index', { root: true })

    return this.$axios
      .$get(`/locations`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
          'X-subdomain': state.selected ? state.selected.id : null,
        },
      })
      .then((data) => {
        commit('TOTAL_ITEMS', data.meta.total)
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'locations.index', { root: true })
        return state.list
      })
      .catch((e) => {
        dispatch('wait/end', 'locations.index', { root: true })
        return e
      })
  },
  fetchItem({ dispatch }, id) {
    dispatch('wait/start', 'locations.show', { root: true })
    return this.$axios
      .$get(`/locations/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'locations.show', { root: true })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Location Show',
          text: e.response.data.errors,
        })
        dispatch('wait/end', 'locations.show', { root: true })
        return Promise.reject(e)
      })
  },
  create({ dispatch }, location) {
    dispatch('wait/start', 'locations.create', { root: true })
    return this.$axios
      .$post(
        `/locations`,
        { ...location },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        dispatch('wait/end', 'locations.create', { root: true })
        dispatch('fetchItems')
        this.$notify({
          type: 'success',
          title: 'Location Create',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.$notify({
            type: 'error',
            title: 'Location Create',
            text: e.response.data.error,
          })
        }
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'locations.create',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'locations.create', { root: true })
        return Promise.reject(e)
      })
  },
  updateItem({ dispatch }, item) {
    dispatch('wait/start', 'locations.edit', { root: true })
    return this.$axios
      .$put(
        `/locations/${item.id}`,
        { ...item },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        this.$notify({
          type: 'success',
          title: 'Location Edit',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        this.$notify({
          type: 'error',
          title: 'Item Edit',
          text: e.response.data.errors,
        })
        return Promise.reject(e)
      })
      .finally(() => {
        dispatch('wait/end', 'locations.edit', { root: true })
      })
  },
  removeItem({ dispatch }, id) {
    dispatch('wait/start', 'locations.delete', { root: true })
    return this.$axios
      .$delete(`/locations/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        if (data.data.owner === 'true') {
          this.$notify({
            type: 'success',
            title: 'Location Delete',
            text: data.message,
          })
        }
        else {
          this.$notify({
            type: 'error',
            title: 'Message',
            text: 'You have not access to delete location.',
          })
        }
        dispatch('wait/end', 'locations.delete', { root: true })
        dispatch('fetchItems')

        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'locations.delete',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'locations.delete', { root: true })
        return Promise.reject(e)
      })
  },
  updateTerminalPageStatus({ commit }, event) {
    commit('SET_TERMINAL_PAGE_STATUS', event)
  },
  modal({ dispatch }, params) {
    return this.$axios
      .$post(
        '/location/permission',
        {
          params,
        },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        const owner = data.owner
        this.owner = owner
        if (this.owner === true) {
          this.$router.push('locations/create')
        }
        else {
          this.$router.push('locations')
          this.$notify({
            type: 'error',
            title: 'Message',
            text: 'You have not access to add new location.',
          })
        }

        dispatch('fetchItems')
        return Promise.resolve()
      })
  },
  setCompanyData({ commit }, { id, subdomain, locations }) {
    commit('SET_ITEMS', locations)

    localStorage.setItem('company-id', id)
    localStorage.setItem('subdomain', subdomain)
  },
  clearSelectedLocation({ commit }) {
    commit('SET_SELECTED_LOCATION', null)
  },
}

export const getters = {
  getTerminalPageStatus: state => state.isTerminalPage,
  getLocations: state => state.list,
  sanitizeLocation: () => (data) => {
    const item = { ...data }
    item.schedules = data.hours.map((hour) => {
      const schedule = { ...hour }
      schedule.start_time
        = isNullOrUndefined(hour.hours[0]) || Number.isNaN(hour.hours[0])
          ? null
          : `${hour.hours[0]}:00:00`
      schedule.end_time
        = isNullOrUndefined(hour.hours[1]) || Number.isNaN(hour.hours[1])
          ? null
          : `${hour.hours[1]}:00:00`
      delete schedule.hours
      return schedule
    })
    delete item.hours
    return item
  },
}
