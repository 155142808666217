<script>
import LoginModal from './LoginModal'
import greenlineLogo from '@/assets/images/landing/greenline-logo.png'

export default {
  name: 'Navigation',
  components: { LoginModal },
  data: () => ({
    logo: greenlineLogo,
    dropdownOpen: false,
    isLoginModalVisible: false,
  }),

  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen
    },
    openLoginModal() {
      // this.isLoginModalVisible = true
      window.location.href = `${process.env.NUXT_APP_URL}/login`
    },
    closeLoginModal() {
      this.isLoginModalVisible = false
    },
  },
}
</script>

<template>
  <nav
    id="header"
    class="fixed z-10 flex flex-wrap items-center justify-between w-full px-4 py-4 bg-teal-500 shadow lg:px-3 xl:px-12"
  >
    <LoginModal :visible="isLoginModalVisible" @close="closeLoginModal" />
    <div class="flex flex-row items-center justify-between w-full lg:w-64">
      <div class="flex items-center text-white">
        <a href="/"><img class="w-2/3 sm:w-1/2 lg:w-5/6" :src="logo"></a>
      </div>

      <div id="nav-toggle" class="flex flex-col justify-start block lg:hidden">
        <button
          class="flex items-center px-3 py-2 text-white border-teal-400 rounded focus:outline-none hover:text-blue-500 hover:border-white"
          @click="toggleDropdown"
        >
          <svg class="w-6 h-6 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
    </div>

    <div
      :class="{ hidden: !dropdownOpen, block: dropdownOpen }"
      class="z-20 flex-grow w-full p-4 mt-2 text-black lg:flex lg:items-center lg:w-auto lg:block lg:mt-0 lg:bg-transparent lg:p-0"
    >
      <ul class="items-center justify-end flex-1 list-reset lg:flex">
        <li class="mr-3">
          <a
            class="inline-block px-4 py-2 font-semibold text-white no-underline transition duration-150 hover:underline"
            href="/features"
          >Features</a>
        </li>
        <li class="mr-3">
          <a
            class="inline-block px-4 py-2 font-semibold text-white no-underline transition duration-150 hover:underline"
            href="/pricing"
          >Pricing</a>
        </li>
        <li class="mr-3">
          <a
            class="inline-block px-4 py-2 font-semibold text-white no-underline transition duration-150 hover:underline"
            href="#free-trial"
          >Free Trial</a>
        </li>
      </ul>
      <button
        id="navAction"
        class="w-24 px-2 py-1 mx-auto mt-4 mr-4 font-normal text-white border-2 border-white rounded-full shadow lg:mr-4 focus:outline-none lg:mx-0 bg-turquoise-500 lg:bg-transparent hover:bg-white hover:text-turquoise lg:mt-0"
        @click="openLoginModal"
      >
        Log In
      </button>
    </div>
  </nav>
</template>
