<script>
import moment from 'moment'
import AppButton from './common/AppButton'
import HeaderCustomerInfo from './HeaderCustomerInfo'
import Avatar from '~/components/Avatar'
import Icon from '~/components/Icon'

export default {
  name: 'TheHeader',
  components: {
    HeaderCustomerInfo,
    AppButton,
    Icon,
    Avatar,
  },
  data: () => ({
    domain: '',
    showModal: false,
    openSort: false,
    currentTime: moment().format('MMM D h:mmA'),
  }),
  computed: {
    user() {
      return this.$auth.user
    },
  },
  async mounted() {
    await this.$auth.fetchUser()
    if (localStorage.getItem('subdomain')) {
      const domain = localStorage.getItem('subdomain')
      this.domain = domain
    }

    this.updateTime()

    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    updateTime() {
      setInterval(() => {
        this.currentTime = moment().format('MMM D h:mmA')
      }, 1000)
    },
    handleClickOutside() {
      const dropdown = this.$el.querySelector('.employee-dropdown-menu')
      if (dropdown && !dropdown.contains(event.target)) {
        this.openSort = false
      }
    },

    async logout() {
      const terminal = this.$store.getters['terminals/actualTerminal']

      await this.$store.dispatch('terminals/release', {
        ...terminal,
        password: '',
        hasLogout: true,
      })

      await this.$store.dispatch('tickets/clearOpenedTickets')
      await this.$auth.$storage.setUniversal('location_slug', null)

      this.$store.dispatch('terminals/clearSelectedTerminal')
      this.$store.dispatch('locations/clearSelectedLocation')

      this.$store.commit('ui/setPosMenuVisible', false)

      await this.$auth.logout()
    },
    newOrder() {
      return this.$store.dispatch('ui/openCustomerSearchModal')
    },
    displayChangePasswordModal() {
      this.showModal = true
    },
    openSubscriptionManagement() {
      return this.$store.dispatch('subscription/openBillingPortal')
    },
    goToEditProfile() {
      try {
        const { id } = this.user

        this.openSort = false

        this.$router.push({
          name: 'employees-index-id',
          params: { id },
        })
      }
      catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<template>
  <div class="flex justify-between w-full h-16 text-xs bg-white">
    <div class="flex items-center">
      <HeaderCustomerInfo class="justify-start h-full" />
    </div>
    <div class="flex items-center">
      <div class="font-medium">
        {{ currentTime }}
      </div>
      <AppButton
        class="mx-2 text-xs font-semibold leading-tight lg:mx-4 lg:w-auto md:w-40"
        @click.native="newOrder"
      >
        <Icon icon="plus" class="mr-2" /> New Order
      </AppButton>
      <div class="flex justify-center">
        <div class="relative employee-dropdown-menu">
          <button
            class="flex items-center justify-center py-2 pr-10 text-sm font-semibold text-left text-white bg-transparent bg-gray-200 rounded-lg focus:outline-none"
            @click="openSort = !openSort"
          >
            <Avatar :src="user.avatar" class="block h-8 mx-4 rounded-full shadow-md" />
          </button>
          <div v-show="openSort" class="absolute right-0 w-48 mt-2 origin-top-right z-100">
            <div
              class="px-2 pt-2 pb-2 bg-white rounded-md shadow-lg dark-mode:bg-gray-700"
            >
              <div class="flex flex-col">
                <a
                  class="flex flex-row items-start p-2 bg-transparent rounded-lg cursor-pointer hover:turquoise hover:bg-gray-200"
                  @click="goToEditProfile"
                >
                  <p class="">
                    Edit Profile
                  </p>
                </a>
                <a
                  v-if="domain !== 'demo-company'"
                  class="flex flex-row items-start p-2 bg-transparent rounded-lg hover:turquoise hover:bg-gray-200"
                  href="#"
                  @click="openSubscriptionManagement"
                >
                  <div class="">
                    <p class="">Manage Subscription</p>
                  </div>
                </a>
                <nuxt-link
                  :to="{
                    name: 'change-password',
                  }"
                  class="flex flex-row items-start p-2 bg-transparent rounded-lg hover:turquoise hover:bg-gray-200"
                >
                  <div class="">
                    <p class="">
                      Change Password
                    </p>
                  </div>
                </nuxt-link>
                <a
                  class="flex flex-row items-start p-2 bg-transparent rounded-lg hover:bg-gray-200"
                  href="#"
                  @click="logout"
                >
                  <div class="">
                    <p class="">Logout</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
