import { flare } from '@flareapp/js'
import Vue from 'vue'

export default () => {
  if (process.client) {
    flare.light(process.env.FLARE_API_KEY)

    if (process.env.NODE_ENV !== 'production') {
      flare.test()
    }

    Vue.prototype.$flare = flare

    Vue.config.errorHandler = (error, vm, info) => {
      flare.report(error, {
        component: vm.$options.name,
        info,
      })
    }
  }
}
