<script>
import greenlineGreenLogo from '@/assets/images/landing/greenline-green-logo.png'

export default {
  name: 'LoginModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    greenlineGreenLogo,
    company_name: '',
    isLoading: false,
    message: '',
  }),
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleCompanyCheck() {
    //   // this.isLoading = true
    //   this.$store.dispatch('landing/getCompanyURL', this.company_name)
    //   // console.log('result: ', result)
    },
  },
}
</script>

<template>
  <div v-show="visible" class="fixed inset-0 top-0 left-0 z-50 flex items-center justify-center h-screen bg-center bg-no-repeat bg-cover outline-none min-w-screen animated fadeIn faster focus:outline-none">
    <div class="absolute inset-0 z-0 bg-black opacity-50" />
    <div class="relative w-full max-w-md p-5 mx-auto my-auto bg-white shadow-lg rounded-xl ">
      <strong class="absolute float-right pt-2 pr-3 ml-3 mr-3 -mt-6 -mr-2 text-xl cursor-pointer right-10 pl-27 align-center alert-del" @click="closeModal()">&times;</strong>
      <!-- content -->
      <div id="payment-modal" class="p-2 modal">
        <div class="flex items-center text-toquise" style="margin-left:94px;margin-bottom:34px">
          <img class="w-2/3 sm:w-1/2 lg:w-5/6" :src="greenlineGreenLogo">
        </div>

        <form method="post" class="flex flex-wrap">
          <div class="flex flex-col w-full px-4 py-2 ">
            <input v-model="company_name" class="px-4 py-3 leading-tight text-gray-700 bg-gray-100 border-2 border-gray-100 rounded appearance-none w-full-login focus:outline-none focus:bg-white focus:border-glteal" type="text" placeholder="Company Name" required>
          </div>
          <div class="flex flex-col w-full px-4 mt-6 lg:px-0">
            <button type="submit" class="inline-flex px-4 py-4 m-auto text-base font-normal leading-none text-white border-2 border-white rounded-full bg-glteal btn-bg-clr hover:bg-white hover:border-glteal hover:text-glteal focus:outline-none" @click="handleCompanyCheck">
              <svg v-show="isLoading" class="=w-5 h-5 mr-3 -ml-1 text-white modal-loader-svg loader-svg animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              Go To URL
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
