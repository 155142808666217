<script>
import SecondaryNav from '../components/Landing/Nav' // Adjust the path as necessary

export default {
  name: 'SecondaryContent',
  components: {
    SecondaryNav,
  },
}
</script>

<template>
  <div>
    <SecondaryNav />
    <nuxt />
  </div>
</template>

<style scoped>
/* Add any necessary styles for the layout */
</style>
