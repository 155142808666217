<script>
import swal from 'sweetalert'
import TextInput from '~/components/common/TextInput'

export default {
  components: { TextInput },
  props: {
    terminal: {
      type: Object,
      default() {
        return {}
      },
    },
    release: {
      type: Boolean,
      default: false,
    },
    redirectToTerminals: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: 'password',
    }
  },
  computed: {
    redirectTo() {
      const { next } = this.$route.query
      return next || '/'
    },
    buttonPlaceholder() {
      if (this.release)
        return 'Release'

      return 'Login'
    },
    location() {
      return this.$store.getters.currentLocation
    },
  },
  async mounted() {
    await this.swalNotification()
  },
  methods: {
    async swalNotification() {
      const swalResponse = await swal({
        text: 'Terminal Password',
        content: this.$el,
        buttons: ['Cancel', { text: this.buttonPlaceholder, closeModal: false }],
      })

      if (!swalResponse) {
        this.$emit('hide', true)
        return
      }

      const response = await this.fetchResponse()

      const { slug } = this.location
      this.$store.dispatch('terminals/fetch', slug)
      swal.stopLoading()

      this.$emit('hide', true)
      if (response === true) {
        swal.close()

        return this.getResponse()
      }

      swal('Woops', response.message, 'error')
    },
    getLocationSlug() {
      if (this.redirectToTerminals)
        return this.location

      return this.$route.params
    },
    getResponse() {
      if (this.release) {
        const { slug } = this.getLocationSlug()

        this.$store.dispatch('terminals/fetch', slug)

        if (this.redirectToTerminals)
          this.$router.push(`/terminals/${slug}`)

        return
      }

      this.$router.push(this.redirectTo)
    },
    fetchResponse() {
      if (this.release)
        return this.releaseTerminal()

      return this.selectTerminal()
    },
    async selectTerminal() {
      const { id } = this.terminal
      await this.$store.dispatch('wait/start', `terminals.lock.${id}`)
      const response = await this.$store.dispatch('terminals/lock', {
        ...this.terminal,
        password: this.password,
      })

      await this.$auth.$storage.setUniversal('mode', null)
      await this.$store.dispatch('wait/end', `terminals.lock.${id}`)
      return response
    },
    async releaseTerminal() {
      const { id } = this.terminal
      await this.$store.dispatch('wait/start', `terminals.release.${id}`)
      const response = await this.$store.dispatch('terminals/release', {
        ...this.terminal,
        password: this.password,
      })
      await this.$store.dispatch('wait/end', `terminals.release.${id}`)

      return response
    },
  },
}
</script>

<template>
  <div>
    <TextInput v-model="password" type="password" />
  </div>
</template>
