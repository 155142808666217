// eslint-disable @typescript-eslint/no-unused-vars -- temporary unused

export default async function ({ store, route, redirect }) {
  const isLoggedIn = store.state.auth.loggedIn
  const hasSelectedLocation = store.state.locations.selected
  const hasSelectedTerminal = store.state.terminals.selected
  const posRoute = route.path.startsWith('/pos')
  const loginRoute = route.path.startsWith('/login')

  const hasQuery = Object.keys(route.query).length > 0

  if (!posRoute)
    return

  if (!isLoggedIn && !loginRoute && !hasQuery) {
    return redirect('/login')
  }

  if (!hasSelectedLocation) {
    return redirect('/location-select')
  }

  const { slug } = hasSelectedLocation

  if (!hasSelectedTerminal) {
    return redirect(`/terminals/${slug}`)
  }

  if (hasSelectedLocation && hasSelectedTerminal) {
    const status = await store.dispatch('terminals/check')

    if (!status) {
      return redirect(`/terminals/${slug}`)
    }
  }
}
